export const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
  },

  {
    title: 'Username',
    dataIndex: 'username',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Area',
    dataIndex: 'group_area',
  },
  {
    title: 'Status',
    dataIndex: 'active',
    slots: { customRender: 'status' },
  },
  {
    title: 'Action',
    slots: { customRender: 'action' },
  },
]
