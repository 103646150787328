<template>
  <div>
    <div class="row">
      <div class="col">
        <filter-role show-search placeholder="Pilih Role" v-model:value="role"></filter-role>
      </div>
      <div class="col">
        <a-input-search v-model:value="q" placeholder="Cari ..."></a-input-search>
      </div>
      <div class="col">
        <a-button type="primary" title="cari" @click="search" :loading="loading">
          <i v-if="!loading" class="fa fa-search" aria-hidden="true"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </div>
      <div class="col"></div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, computed } from 'vue'
import { useRoute } from 'vue-router'

import FilterRole from '@/components/filter/FilterRole'
export default {
  name: 'MFilter',
  components: {
    FilterRole,
  },
  props: {
    filter: {
      type: Object,
      default: () => {
        active: null
        q: null
        role: []
        area: []
      },
    },
    loading: [Boolean],
  },
  emits: ['search', 'update:filter'],
  setup(props, { emit }) {
    const route = useRoute()
    const role_id = computed(() => route.query.role_id)

    const filters = reactive({
      active: null,
      active: null,
      q: null,
      role: [],
      area: [],
    })

    filters.role.push(Number(role_id.value))
    const search = () => {
      emit('update:filter', Object.assign({}, filters))
    }
    search()

    return { emit, ...toRefs(filters), search }
  },
}
</script>

<style scoped></style>
