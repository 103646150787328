<template>
  <div class="">
    <div class="table-responsive text-nowrap">
      <md-table
        v-if="meta"
        :columns="columns"
        :data-source="source.items"
        size="small"
        row-key="id"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
          showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
          total: meta.totalCount,
          pageSize: meta.perPage,
          current: meta.currentPage,
        }"
        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        @change="handleTableChange"
        :loading="isFetching"
      >
        <template #no="{ index }">
          <span>
            {{ startRow + index }}
          </span>
        </template>
        <template #action="{record}">
          <span>
            <a-tooltip title="Lihat Role">
              <a-button class="button" size="small" @click="lihatRole(record)">
                <i class="fe fe-eye" />
              </a-button>
            </a-tooltip>
            <a-tooltip title="Ubah Role">
              <a-button class="button" size="small" @click="ubahRole(record)">
                <i class="fe fe-edit" />
              </a-button>
            </a-tooltip>
          </span>
        </template>
        <template #status="{ text }">
          <span
            :class="[
              text === true
                ? 'font-size-12 badge badge-primary'
                : 'font-size-12 badge badge-default',
            ]"
            >{{ text ? 'Aktif' : 'Non Aktif' }}</span
          >
        </template>
      </md-table>
    </div>
    <m-modal
      v-if="show"
      v-model:show="show"
      v-model:state="state"
      @refresh="refresh(param)"
    ></m-modal>
  </div>
</template>

<script>
import { columns } from './columns'
import { reactive, watch, ref, computed } from 'vue'
import apiClient from '@/services/axios'
import MModal from './modal'

export default {
  components: {
    MModal,
  },
  props: {
    param: {
      type: Object,
      default: () => {},
    },
    loading: [Boolean],
  },
  emits: ['update:loading'],
  setup(props, { emit }) {
    const source = ref({
      _meta: {
        totalCount: 0,
        perPage: 10,
        currentPage: 1,
      },
    })
    const startRow = ref(1)
    const meta = computed(() => source.value._meta)
    const isFetching = ref(false)
    const params = reactive({
      page: 1,
      perPage: 10,
    })
    const refresh = (param = {}) => {
      isFetching.value = true
      emit('update:loading', true)

      if (param._export === 'xls') {
      } else {
        apiClient
          .get('/api/users', {
            params: {
              page: params.page,
              'per-page': params.perPage,
              ...param,
            },
          })
          .then(({ data }) => {
            source.value = data
          })
          .finally(() => {
            isFetching.value = false
            emit('update:loading', false)
          })
      }
    }

    const handleTableChange = pag => {
      params.page = pag.current
      params.perPage = pag.pageSize
      refresh(props.param)
      startRow.value = (pag.current - 1) * pag.pageSize + 1
    }
    watch(
      () => props.param,
      val => {
        if (val.time_requested) {
          val.time_requested = undefined
          return
        }
        refresh(val)
      },
      { deep: true },
    )

    // calling modal
    const show = ref(false)
    const state = reactive({
      title: null,
      record: null,
      readOnly: null,
    })
    const lihatRole = record => {
      show.value = true
      state.title = 'Lihat Role'
      state.record = record
      state.readOnly = true
    }
    const ubahRole = record => {
      show.value = true
      state.title = 'Ubah Role'
      state.record = record
      state.state = 'edit'
      state.readOnly = false
    }

    return {
      startRow,
      columns,
      source,
      meta,
      handleTableChange,
      isFetching,
      lihatRole,
      show,
      state,
      ubahRole,
      refresh,
    }
  },
}
</script>

<style scoped>
.button {
  margin: 0 4px;
}
</style>
